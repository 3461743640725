import React, { useState } from 'react';
import './Popup.css'; // Assume styles are moved to Popup.css for clean structure

function PrivacyNotice() {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const showPopup = () => setPopupVisible(true);
  const closePopup = () => setPopupVisible(false);

  return (
    <div className="">
      <button className="show-popup-button" onClick={showPopup}>
        Privacy Notice
      </button>

      {isPopupVisible && (
        <div className="popup-container">
          <div className="popup">
            <h2>Privacy Policy for Nayan.sa</h2>
            <p>
Effective Date: 2025

Welcome to Nayan.sa. Your privacy is important to us, and we are committed to protecting your personal data. This Privacy Policy explains how we collect, use, and safeguard your information when you interact with our website and marketing services.

1. Information We Collect

We may collect the following types of information for marketing purposes:

Personal Information: Name, email address, phone number, and other contact details.

Usage Data: Information on how you interact with our website, such as pages visited, time spent, and actions taken.

Cookies & Tracking Technologies: We may use cookies, web beacons, and similar technologies to enhance your experience and analyze user behavior.

2. How We Use Your Information

We use the collected information for:

Sending promotional emails, SMS, and notifications about our services and offers.

Conducting surveys and gathering feedback to improve our offerings.

Customizing advertisements and marketing campaigns to match user preferences.

Analyzing user behavior to enhance our marketing strategies.

3. Sharing Your Information

We do not sell your personal data. However, we may share it with:

Marketing Partners: Third-party service providers who help us run campaigns and analyze data.

Legal Authorities: If required by law, we may disclose information to comply with legal obligations.

4. Your Rights and Choices

You have the right to:

Opt out of receiving marketing communications by clicking the unsubscribe link in emails or contacting us.

Request access, modification, or deletion of your personal data.

Manage cookie preferences through your browser settings.

5. Data Security

We implement security measures to protect your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet is 100% secure.

6. Changes to This Policy

We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.

7. Contact Us

If you have any questions about this Privacy Policy, please contact us at:

Email: info@nayan.sa

Website: nayan.sa</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrivacyNotice;