import React, { Component } from "react";
import Countdown from 'react-countdown';
import { Link } from "react-router-dom";
import "../MainPage/OurProducts.css";
import MapWrapper from "./MapComponents/MapWrapper";
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";
import n2909 from "../../assets/imgs/ProjectsPhotos/Nayan 2909/2909-6.jpg";
import n3299 from "../../assets/imgs/ProjectsPhotos/Nayan 3299/3299-6.jpg";
import n3569 from "../../assets/imgs/ProjectsPhotos/Nayan 3569/3569-10.jpg";
import n3832 from "../../assets/imgs/ProjectsPhotos/Nayan 3832/3832-11.jpg"
import n4845 from "../../assets/imgs/ProjectsPhotos/Nayan 4845/4845-0.jpg"
import n3794 from "../../assets/imgs/ProjectsPhotos/Nayan 3794/3794-0.jpg"
import n7119 from "../../assets/imgs/ProjectsPhotos/Nayan 7119/7119-0.jpg"
import n from "../../assets/imgs/ProjectsPhotos/def.jpg"
import av from "../../assets/icons/MapsMarkers/marker-av.png";
import sd from "../../assets/icons/MapsMarkers/marker-sd.png";
import so from "../../assets/icons/MapsMarkers/marker-so.png";
import building from "../../assets/icons/building.png";
import unit from "../../assets/icons/021-rent.png";
import area from "../../assets/icons/blueprint-2.png";
import villas from "../../assets/icons/duplex.png";
import page from "../../assets/icons/more.png";

const renderer = ({days, hours, minutes, seconds}) => {
    // Render a countdown
    return (
        <table className="countdown-table">
            <tbody>
            <tr>
                <td className="box-f">{seconds}</td>
                <td className="box-f">{minutes}</td>
                <td className="box-f">{hours}</td>
                <td className="box-f">{days}</td>
            </tr>
            <tr>
                <td>ثانية</td>
                <td>دقيقة</td>
                <td>ساعة</td>
                <td>يوم</td>
            </tr>
            </tbody>
        </table>);
};

const CardUi = props => {
    const isSoon = props.currentstatus==="soon";
    const isSold = props.currentstatus==="sold";
    const isAv = props.currentstatus==="available";
    const isRe = props.currentstatus==="availablerent";
    const isNoRe = props.currentstatus==="notavailablerent";
    return (
        <Card className="project-card" as={Link} to={props.page} bg="light" style={{ cursor: "pointer" }}>
            <div className={props.status}><span>
                {isSoon? (
                    <Countdown
                    date={props.date}
                    renderer={renderer}
                    />
                ) : (
                    null
                )}
                {isSold? (
                    <div>مباع</div>
                ) : (
                    null
                )}
                {isAv? (
                    <div>متاح</div>
                ) : (
                    null
                )}
                {isRe? (
                    <div>متاح للإيجار</div>
                ) : (
                    null
                )}
                {isNoRe? (
                    <div>مؤجرة بالكامل</div>
                ) : (
                    null
                )}
            </span>
            </div>
            <Card.Img variant="top" src={props.srcimg} />
            <Card.Body>
                <Card.Title className="box-f">{props.title}</Card.Title>
                <div className="container p-0">
                    <div className="row row-features">
                        <div className="col-3">
                            <div className="row">
                                <img alt="" className="icon-feature" src={props.srcicon4}></img>
                            </div>
                            <div className="row"><span className="box-f">{props.feature4}</span></div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <img alt="" className="icon-feature" src={props.srcicon3}></img>
                            </div>
                            <div className="row"><span className="box-f">{props.feature3}م<sup>٢</sup></span></div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <img alt="" className="icon-feature" src={props.srcicon2}></img>
                            </div>
                            <div className="row"><span className="box-f">{props.feature2}</span></div>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <img alt="" className="icon-feature" src={props.srcicon1}></img>
                            </div>
                            <div className="row"><span className="box-f">{props.feature1}</span></div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export default class OurProducts extends Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        pauseOnHover: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <section id="OurProducts">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="container text-center">
                            <div className="content-section-heading">
                                <h1>مشاريعنا</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pb-3">
                    <div className="row justify-content-md-center carousel-client">
                        <Slider {...settings}>
                        <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2021, 12, 10, 0, 0, 0)}
                                page="/3794"
                                currentstatus="sold"
                                srcimg={n3794}
                                title="نيان الملقا 3794"
                                srcicon1={building}
                                feature1="شقق تمليك"
                                srcicon2={unit}
                                feature2="٢٧ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white notavailablerent"
                                date = {new Date(2022, 11, 1, 0, 0, 0)}
                                page="/3839"
                                currentstatus="notavailablerent"
                                srcimg={n}
                                title="نيان النرجس 3839"
                                srcicon1={building}
                                feature1="شقق تأجير"
                                srcicon2={unit}
                                feature2="٢٧ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white available"
                                date = {new Date(2004, 11, 25, 0, 0, 0)}
                                page="/2880"
                                currentstatus="available"
                                srcimg={n}
                                title="نيان النرجس 2880"
                                srcicon1={villas}
                                feature1="تاون هاوس"
                                srcicon2={unit}
                                feature2="٤٨ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white available"
                                date = {new Date(2004, 11, 25, 0, 0, 0)}
                                page="/7119"
                                currentstatus="available"
                                srcimg={n7119}
                                title="نيان الملقا 7119"
                                srcicon1={building}
                                feature1="شقق تمليك"
                                srcicon2={unit}
                                feature2="٢٦ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>

                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2022, 4, 23, 0, 0, 0)}
                                page="/7110"
                                currentstatus="sold"
                                srcimg={n}
                                title="نيان الملقا 7110"
                                srcicon1={villas}
                                feature1="تاون هاوس"
                                srcicon2={unit}
                                feature2="١٥ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٦٤-٥٣٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2022, 4, 23, 0, 0, 0)}
                                page="/4845"
                                currentstatus="sold"
                                srcimg={n4845}
                                title="نيان الملقا 4845"
                                srcicon1={villas}
                                feature1="تاون هاوس"
                                srcicon2={unit}
                                feature2="٩ وحدات سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"    
                                date = {new Date(2022, 4, 23, 0, 0, 0)}
                                page="/3299"
                                currentstatus="sold"
                                srcimg={n3299}
                                title="نيان الياسمين 3299"
                                srcicon1={building}
                                feature1="شقق تمليك"
                                srcicon2={unit}
                                feature2="٢٦ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2020, 1, 1, 0, 0, 0)}
                                page="/7580"
                                currentstatus="sold"
                                srcimg={n}
                                title="نيان المونسية 7580"
                                srcicon1={building}
                                feature1="شقق تمليك"
                                srcicon2={unit}
                                feature2="١٤ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٩٠-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2021, 12, 10, 0, 0, 0)}
                                page="/3832"
                                currentstatus="sold"
                                srcimg={n3832}
                                title="نيان الملقا 3832"
                                srcicon1={villas}
                                feature1="تاون هاوس"
                                srcicon2={unit}
                                feature2="٢٤ وحدة سكنية"
                                srcicon3={area}
                                feature3="٢٠٠-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2022, 1, 31, 0, 0, 0)}
                                page="/2909"
                                currentstatus="sold"
                                srcimg={n2909}
                                title="نيان الملقا 2909"
                                srcicon1={building}
                                feature1="شقق تمليك"
                                srcicon2={unit}
                                feature2="٢٩ وحدة سكنية"
                                srcicon3={area}
                                feature3="١٣٧-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                            <CardUi
                                status="status text-center text-white sold"
                                date = {new Date(2023, 1, 31, 0, 0, 0)}
                                page="/3569"
                                currentstatus="sold"
                                srcimg={n3569}
                                title="نيان القيروان 3569"
                                srcicon1={villas}
                                feature1="تاون هاوس"
                                srcicon2={unit}
                                feature2="٩ وحدات سكنية"
                                srcicon3={area}
                                feature3="١٩٠-٣٥٠"
                                srcicon4={page}
                                feature4="اضغط للمزيد">
                            </CardUi>
                        </Slider>
                    </div>
                </div>
                <div>
                    <MapWrapper />
                    <div id="map"></div>
                    <div className="container" id="legend">
                        <div className="row row-legend">
                            <div className="col-4">
                                <span><img alt="" className="img-legend" src={av} /><div>متاح</div></span>
                            </div>
                            <div className="col-4">
                                <span><img alt="" className="img-legend" src={sd} /> <div>مباع</div></span>
                            </div>
                            <div className="col-4">
                                <span><img alt="" className="img-legend" src={so} /><div>تحت الإنشاء</div></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

//https://www.npmjs.com/package/react-responsive-carousel
//https://codesandbox.io/s/react-responsive-carousel-forked-6ncggt?file=/src/index.js
