import React, {useState, useEffect, useRef} from "react";
import emailjs from '@emailjs/browser';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import whatsapp from '../../assets/icons/whatsmain.png';
import { Container, Col, Row } from "react-bootstrap";
import Fade from 'react-bootstrap/Fade';
import "../MainPage/RentButton.css";
const RentButton = () => {
  const form = useRef(null);
  const [statusMessage, setStatusMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_w1ypnpf', 'template_zsgjyfq', form.current, 'GpjnKM2NC54EdunnF')
      .then((result) => {
        console.log(result.text);
        setStatusMessage("تم ارسال ردك بنجاح .. سنتواصل معك في اقرب وقت ممكن");
      }, (error) => {
          console.log(error.text);
          setStatusMessage(`حدث خطأ في الارسال`);
      });
      e.target.reset();
  };
  const [RentButton, setRentButton] = useState(false);
  useEffect(() => {
    var prOffsetTop = document.getElementById('OurProducts').getBoundingClientRect().top;
    window.addEventListener("scroll", () => {
      var diff1 = (document.documentElement.scrollTop - prOffsetTop + window.screen.height);
      if (diff1 > 0) {
        setRentButton(true);
      }
    })
  }, [])
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      {RentButton && (
        <Fade in={RentButton} >
          <div className="side-button">
          <Button className="whats-button" src target="_blank" href="https://api.whatsapp.com/send/?phone=966561291512&text&type=phone_number&app_absent=0">
          <img
              src={whatsapp}
              alt="Whicon"
              width="60"
              height="60"
            ></img> 
          </Button>
          </div>
        </Fade>
      )}
      <Modal
        size="md"
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>نموذج الإيجار</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="row-border mb-4">
              <Col ><div className="big-title">سجل بياناتك</div>
                <div className="st">قم من فضلك بتعبئة التفاصيل التالية</div></Col>
            </Row>
            <form ref={form} onSubmit={sendEmail}>
        <p className="message-status">{statusMessage}</p>
        <Row className="row-border">
          <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="name" name="name" placeholder="الاسم*" required/></div>
          <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="mobile" name="mobile" placeholder="رقم الجوال (05xxxxxxxx)*" required maxLength="10" min="9" onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault(); }}}/></div>
        </Row>
        <Row className="row-border">
          <div className="col-lg-6 col-sm-12">
            <select id="interest" name="interest" className="form-control line">
              <option value=""> مكان الحي</option>
              <option value="القيروان">القيروان</option>
              <option value="الملقا">الملقا</option>
              <option value="قرطبة">قرطبة</option>
              <option value="الياسمين">الياسمين</option>
            </select>
          </div>
          <div className="col-lg-6 col-sm-12"><input type="text" className="form-control line" id="details" name="details" placeholder="مواصفات الشقة" required/></div>
        </Row>
        <Row className="row-border row justify-content-md-center center-1">
          <Button className="new-Btn-2" type="submit" value="Send" variant="dark" >إرسال</Button>
          </Row>
        </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" className="cardBtn" onClick={handleClose}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default RentButton;